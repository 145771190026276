
import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
import { Chart, registerables, Tooltip } from "chart.js";
import dayjs from "dayjs";
import gradient from "chartjs-plugin-gradient";
import TooltipChart from "~/components/organisms/chart/TooltipChart.vue";
import { getTooltipDataFromContext } from "~/operations/charts";

type dataSets = Array<{
  x: string;
  y: number;
}>;

type labels = Array<string | number>;

@Component({
  components: {
    TooltipChart,
  },
})
export default class CityLineChart extends Vue {
  @Prop() labels: labels;
  @Prop() dataSets: dataSets;

  chart: any = {};

  get _labels() {
    return this.labels;
  }

  get _dataSets() {
    return this.dataSets;
  }
  tooltipData: object = {};
  PriceChartTooltip = "CityLineChartTooltip";
  tooltipPosition: object = {
    xAlignleftS: 15,
    xAlignleftL: 55,
    top: 50,
    xAlignright: -55,
    breakpoint: 680,
    LeftBottom: 20,
    center: -95,
  };

  setTooltipData(data) {
    this.tooltipData = data;
  }
  // 1 trigger
  externalTooltipHandler = (context) => {
    if (context.tooltip.body) {
      this.setTooltipData(getTooltipDataFromContext(context));
      const { chart, tooltip } = context;
      this.tooltipData = context;
    }
  };

  @Watch("_dataSets")
  updateChart() {
    if (this._dataSets.length && this._labels.length) {
      this.chart.data.datasets[0].data = this._dataSets;
      this.chart.data.labels = this._labels;
      this.chart.update();
    }
  }

  mounted() {
    this.initRenderChart();
    //@ts-ignore
    Tooltip.positioners.custom = function (elements, eventPosition) {
      const tooltip = this;
      return {
        x: eventPosition.x,
        y: eventPosition.y,
      };
    };
  }

  chartjsOptions = {
    type: "line",
    data: {
      labels: this._labels,
      datasets: [
        {
          label: "",
          data: this._dataSets,
          borderColor: ["#000"],
          borderWidth: 2,
          pointRadius: 0,
          pointHoverBackgroundColor: "#000",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 3,
          pointHoverRadius: 6,
          backgroundColor: "transparent",
          fill: true,
          gradient: {
            backgroundColor: {
              axis: "y",
              colors: {
                0: "rgba(142, 142,142, 0.0)",
                100: "rgba(142, 142,142, 0.05)",
              },
            },
          },
        },
      ],
    },
    options: {
      aspectRatio: (context) => (window.innerWidth < 700 ? 1 | 0.2 : 0.5 | 2),
      responsive: true,
      lineTension: 1,
      spanGaps: true,
      elements: {
        line: {
          cubicInterpolationMode: "monotone",
        },
      },
      title: {
        display: false,
        text: "Heizölpreis-Entwicklung in Deutschland",
        fontSize: 22,
        position: "bottom" as const,
      },

      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        y: {
          position: "right",
          ticks: {
            beginAtZero: false,
            maxTicksLimit: 7,
            stepSize: 5,
            fontSize: 12,
            color: "#7d7d7d",
            callback(value) {
              return `${value} €`;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "€ / 100 L",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
        x: {
          type: "category",
          distribution: "linear" as const,
          ticks: {
            autoSkip: true,
            maxTicksLimit: () => (window.innerWidth < 620 ? 3 : 6),
            maxRotation: 0,
            fontSize: 12,
            color: "#7d7d7d",
            align: "center",
            callback: (context) => {
              const splittedDate = this._labels[context].toString().split(".");
              if (window.innerWidth < 620) {
                return dayjs(
                  `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
                ).format("MMM");
              } else {
                return this._labels[context];
              }
            },
          },
          scaleLabel: {
            display: false,
            labelString: "",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            zeroLineColor: "#cbd0d2",
            zeroLineBorderDash: [3, 9],
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
      },

      plugins: {
        tooltip: {
          enabled: false,
          external: this.externalTooltipHandler,
          position: "custom",
          mode: "index",
          intersect: false,
          displayColors: false,
          xPadding: 20,
          yPadding: 20,
          caretPadding: 5,
          cornerRadius: 5,
          borderWidth: 1,
          bodySpacing: 5,
          borderColor: "#000",
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.datasetIndex === 0) {
                let hoveredPrice = tooltipItem.formattedValue;
                let todaysPrice =
                  tooltipItem.dataset.data[tooltipItem.dataset.data.length - 1]
                    .y;
                return [`${hoveredPrice} € `, `Heute: ${todaysPrice} € `];
              }
            },
          },
        },
        legend: {
          display: false,
          position: "bottom" as const,
          align: "start" as const,
          labels: {
            fontColor: "rgba(0,0,0)",
            usePointStyle: true,
            boxWidth: 5,
            padding: 10,
          },
        },
        crosshair: {
          sync: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
          line: {
            color: "hsla( 10, 1%, 1%, 1 )",
            width: 1,
            dashPattern: [10, 3],
          },
        },
      },
    },
  };
  initRenderChart() {
    const ctx = document.getElementById("CityLineChart") as HTMLCanvasElement;
    Chart.register(CrosshairPlugin);
    Chart.register(...registerables);
    Chart.register(gradient);

    this.chart = new Chart(ctx, {
      type: this.chartjsOptions.type as any,
      data: this.chartjsOptions.data,
      options: this.chartjsOptions.options,
    });
  }
}
